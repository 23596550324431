import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logoutAction } from "../../redux/actions/authActions";
import { NavLink } from "react-router-dom";
import { CiUser } from "react-icons/ci";
import {
  MdOutlineLogout,
  MdOutlineNotifications,
  MdOutlinePriceChange,
} from "react-icons/md";
import "./profile.css";

const ProfileDropdown = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dropdownRef = useRef(null); // Ref to track the dropdown container

  // Use a delay for closing the dropdown
  const closeDropdownTimeout = useRef(null);

  const firstLetters = (username) => {
    if (username && username.length >= 2) {
      return username?.substring(0, 2).toUpperCase();
    } else if (username) {
      return username?.toUpperCase();
    }
    return " ";
  };

  const handleLogout = () => {
    dispatch(logoutAction());
  };

  // Clear the close timeout
  const clearCloseTimeout = () => {
    if (closeDropdownTimeout.current) {
      clearTimeout(closeDropdownTimeout.current);
    }
  };

  const handleMouseEnter = () => {
    clearCloseTimeout();
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    clearCloseTimeout(); // Clear any existing timeouts
    closeDropdownTimeout.current = setTimeout(() => {
      setIsOpen(false);
    }, 500); // Close the dropdown after a delay
  };

  // Cleanup on component unmount
  useEffect(() => {
    return () => clearCloseTimeout();
  }, []);

  return (
    <div
      style={{ position: "relative" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      ref={dropdownRef}
    >
      <button className="header-profile-btn">
        <span>{firstLetters(user?.name)}</span>
      </button>

      {isOpen && (
        <div className="profile-menu-btn">
          <button>
            {user && user?.role === "admin" && (
              <NavLink
                to={`/admin/${user.pharmacy}/profile/information`}
                className="dropdown-link"
              >
                <CiUser className="btn-svg" /> My Profile
              </NavLink>
            )}
            {user && user?.role === "manager" && (
              <NavLink
                to={`/manager/${user.pharmacy}/profile/information`}
                className="dropdown-link"
              >
                <CiUser className="btn-svg" /> My Profile
              </NavLink>
            )}
            {user && user?.role === "driver" && (
              <NavLink
                to={`/driver/${user.pharmacy}/profile/information`}
                className="dropdown-link"
              >
                <CiUser className="btn-svg" /> My Profile
              </NavLink>
            )}
            {user && user?.role === "superadmin" && (
              <NavLink
                to={`/super-admin/profile/information`}
                className="dropdown-link"
              >
                <CiUser className="btn-svg" /> My Profile
              </NavLink>
            )}
          </button>
          <button>
            {user && user?.role === "admin" && (
              <NavLink to={`/admin/${user.pharmacy}/plan`}>
                <MdOutlinePriceChange className="btn-svg" /> My Plan
              </NavLink>
            )}
          </button>
          {/* Notifications */}
          <button>
            {user && user?.role === "admin" && (
              <NavLink to={`/admin/${user.pharmacy}/notifications`}>
                <MdOutlineNotifications className="btn-svg" /> Notifications
              </NavLink>
            )}
          </button>
          <button onClick={handleLogout}>
            <MdOutlineLogout className="btn-svg" />
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileDropdown;
