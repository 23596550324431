import React, { useState } from "react";
import "./PatientTable.css";
const PatientsTable = ({
  patients = [],
  currentPage,
  onSelect,
  onPageChange,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const patientsPerPage = 8;

  // Filter patients based on search term (name or NHS number)
  const filteredPatients =
    searchTerm.length > 0
      ? patients.filter(
          (patient) =>
            patient.displayName
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            patient.nhsNumber.includes(searchTerm)
        )
      : patients;

  let currentPatients = [];
  let totalPages = 0;

  if (filteredPatients.length > 0) {
    const indexOfLastPatient = currentPage * patientsPerPage;
    const indexOfFirstPatient = indexOfLastPatient - patientsPerPage;
    currentPatients = filteredPatients.slice(
      indexOfFirstPatient,
      indexOfLastPatient
    );
    totalPages = Math.ceil(filteredPatients.length / patientsPerPage);
  }

  // Handle copy to clipboard
  const handleCopy = (patient) => {
    const patientInfo = `Full Name: ${patient.displayName}, NHS Number: ${patient.nhsNumber}`;
    navigator.clipboard.writeText(patientInfo);
    onSelect(patient); // Call onSelect when a patient is copied
  };

  return (
    <>
      <div className="patient-search-container">
        <input
          type="text"
          placeholder="Search by name or NHS number..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>
      {filteredPatients.length > 0 ? (
        <div className="table-container mt-4">
          <table className="table table-bordered w-100">
            <thead>
              <tr>
                <th>Full Name</th>
                <th>Copy</th>
              </tr>
            </thead>
            <tbody>
              {currentPatients.map((patient, index) => (
                <tr key={index}>
                  <td>
                    {patient?.firstName +
                      " " +
                      patient.middleName +
                      " " +
                      patient?.lastName}
                  </td>
                  <td>
                    <button
                      onClick={() => handleCopy(patient)}
                      className="medtrakr-btn"
                    >
                      Request Rx
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination m-10">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i}
                onClick={() => onPageChange(i + 1)}
                className="medtrakr-btn"
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      ) : (
        <div className="no-patients-message">
          No patients found. Try adjusting your search.
        </div>
      )}
    </>
  );
};

export default PatientsTable;
