import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import enUS from "date-fns/locale/en-US";
import { Modal, Button, Form } from "react-bootstrap";
import format from "date-fns/format";
import parse from "date-fns/parse";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { useDispatch, useSelector } from "react-redux";
import {
  createMdsByPharmacyAction,
  updateMdsByPharmacyAction,
  updateMdsByPharmacyAndRepeatCycleAction,
  fetchMdsByPharmacyAction,
  deleteMdsByPharmacyAction,
  deleteMdsByPharmacyAndRepeatCycleAction,
} from "../../redux/actions/mdsActions";
import { fetchMdsRepeatCyclesByPharmacyAction } from "../../redux/actions/mdsRepeatCycleAction";
import { createNotificationByPharmacyAction } from "../../redux/actions/notificationActions";
import { CiTrash } from "react-icons/ci";
import { MdAddchart } from "react-icons/md";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ErrorNotification } from "../Notifications/ToastNotifications";
import { useParams } from "react-router-dom";
import "./calendar.css";
const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const DraggableCalendar = withDragAndDrop(Calendar);
const MdsCalendar = ({ selectedPatient, onModalClose }) => {
  const dispatch = useDispatch();
  const { pharmacyId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [repeatEnabled, setRepeatEnabled] = useState(false);
  const [deleteConfirmModalShow, setDeleteConfirmModalShow] = useState(false);
  const [newEvent, setNewEvent] = useState({
    medicationDetails: [{ name: "", dosage: "", quantity: "" }],
  });
  const [editedEvent, setEditedEvent] = useState(null);
  const [eventClickShowModal, setEventClickShowModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const events = useSelector((state) => state.mds.mdsState);
  const user = useSelector((state) => state.auth.user);
  const mdsRepeatCycles = useSelector(
    (state) => state.mdsRepeatCycle.mdsRepeatCycles
  );

  // Handle start date change and automatically set end date
  const handleStartDateChange = (start) => {
    const startDate = new Date(start);
    const endDate = new Date(startDate.getTime() + 30 * 60 * 1000); // Set end date 30 minutes after start

    setNewEvent({
      ...newEvent,
      start: formatDateForInput(startDate),
      end: formatDateForInput(endDate),
    });
  };

  // Add a new medication field
  const handleAddMedication = () => {
    setNewEvent({
      ...newEvent,
      medicationDetails: [
        ...newEvent.medicationDetails,
        { name: "", dosage: "", quantity: "" },
      ],
    });
  };

  // Handle medication details changes
  const handleMedicationChange = (index, field, value) => {
    const updatedMedications = [...newEvent.medicationDetails];
    updatedMedications[index][field] = value;
    setNewEvent({ ...newEvent, medicationDetails: updatedMedications });
  };

  // Remove a medication detail
  const handleRemoveMedication = (index) => {
    const filteredMedications = newEvent.medicationDetails.filter(
      (_, idx) => idx !== index
    );
    setNewEvent({ ...newEvent, medicationDetails: filteredMedications });
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditedEvent(null); // Clear the edited event
    resetFormState(); // Reset the form state when modal closes
    onModalClose(); // Reset the selected patient when modal closes
  };

  // Event Click Open and Close

  const handleEventShowModal = (event) => {
    setSelectedEvent(event);
    setEventClickShowModal(true);
  };

  const handleEventCloseModal = (event) => {
    setEventClickShowModal(false);
    setSelectedEvent(null);
  };

  const handleSaveEvent = () => {
    const { medicationDetails } = newEvent;
    let mdsData = {
      patient: newEvent.patientId,
      pharmacy: pharmacyId,
      start: new Date(newEvent.start),
      end: new Date(newEvent.end),
      medicationDetails,
      mdsRepeatCycle: repeatEnabled
        ? {
            frequency: newEvent.repeatFrequency,
            repeatUntil: newEvent.repeatUntil
              ? new Date(newEvent.repeatUntil)
              : undefined,
            ...(newEvent.repeatFrequency === "Custom" && {
              customPattern: newEvent?.customPattern,
            }),
          }
        : undefined,
    };

    if (editedEvent) {
      if (editedEvent.mdsRepeatCycle) {
        // Updating an Mds with a repeat cycle
        dispatch(
          updateMdsByPharmacyAndRepeatCycleAction(
            pharmacyId,
            editedEvent.mdsRepeatCycle, // Assuming the repeatCycle has an _id field
            mdsData
          )
        )
          .then(() => {
            dispatch(
              createNotificationByPharmacyAction(pharmacyId, {
                content: `Medication schedule cycle for ${newEvent.patientName} updated...`,
                user: user._id,
                forRole: ["admin", "manager", "pharmacist"],
                priority: "medium",
              })
            );
            getEvents(); // Refresh the events list
            getMdsRepeatCycles();
            setNewEvent({});
            handleCloseModal();
          })
          .catch((error) => {
            ErrorNotification(error);
          });
      } else {
        // Updating an Mds without a repeat cycle
        dispatch(
          updateMdsByPharmacyAction(pharmacyId, editedEvent._id, mdsData)
        )
          .then(() => {
            handleCloseModal();
          })
          .catch((error) => {
            ErrorNotification(error);
          });
      }
    } else {
      // Creating a new Mds
      dispatch(createMdsByPharmacyAction(pharmacyId, mdsData))
        .then(() => {
          dispatch(
            createNotificationByPharmacyAction(pharmacyId, {
              content: `New medication cycle for ${newEvent.patientName} has been created from ${newEvent.start} to ${newEvent.end}`,
              user: user._id, // Assuming user._id is available from the state
              forRole: ["admin", "manager"],
              priority: "high",
            })
          );
          handleCloseModal();
          getEvents();
          getMdsRepeatCycles();
        })
        .catch((error) => {
          ErrorNotification(error);
        });
    }
  };

  const resetFormState = () => {
    setNewEvent({
      medicationDetails: [{ name: "", dosage: "", quantity: "" }],
      start: "",
      end: "",
      patientName: "",
      nhsNumber: "",
      patientId: "",
      notes: "",
      repeatFrequency: "None",
      repeatUntil: "",
      customPattern: "",
    });
    setRepeatEnabled(false);
  };
  const handleEditEvent = () => {
    setEditedEvent(selectedEvent); // Mark the event as being edited
    setNewEvent({
      // Ensure dates are formatted for input fields if necessary
      ...selectedEvent,
      start: formatDateForInput(selectedEvent.start),
      end: formatDateForInput(selectedEvent.end),
    });
    setEventClickShowModal(false); // Close the Event Details modal
    setShowModal(true); // Open the Edit Event modal
  };
  const findRepeatCycleFrequency = (id) => {
    const mdsRepeatCycle = mdsRepeatCycles.find((val) => val._id === id);
    return mdsRepeatCycle?.frequency;
  };
  const findCustomPattern = (id) => {
    const mdsRepeatCycle = mdsRepeatCycles.find((val) => val._id === id);
    return mdsRepeatCycle?.customPattern ? mdsRepeatCycle?.customPattern : "";
  };
  const findRepeatUntil = (id) => {
    const mdsRepeatCycle = mdsRepeatCycles.find((val) => val._id === id);
    return mdsRepeatCycle?.repeatUntil;
  };

  // Function to handle opening the modal to edit all occurrences
  const handleEditAllOccurrences = () => {
    setEditedEvent(selectedEvent); // Prepare to edit the event
    setRepeatEnabled(true);
    setNewEvent({
      // Ensure dates are formatted for input fields if necessary
      ...selectedEvent,
      patientName: `${selectedEvent.patient.title} ${selectedEvent.patient.firstName} ${selectedEvent.patient.lastName}`,
      nhsNumber: selectedEvent.patient.nhsNumber,
      medicationName: selectedEvent.medicationDetails[0].name,
      patientId: selectedEvent.patient._id,
      dosage: selectedEvent.medicationDetails[0].dosage,
      start: formatDateForInput(selectedEvent.start),
      end: formatDateForInput(selectedEvent.end),
      repeatFrequency: findRepeatCycleFrequency(selectedEvent.mdsRepeatCycle),
      customPattern: findCustomPattern(selectedEvent.mdsRepeatCycle),
      repeatUntil: formatDateForInput(
        findRepeatUntil(selectedEvent.mdsRepeatCycle)
      ),
    });
    setEventClickShowModal(false); // Close the Event Details modal
    setShowModal(true);
  };

  const handleDeleteEvent = () => {
    dispatch(deleteMdsByPharmacyAction(pharmacyId, selectedEvent._id))
      .then(() => {
        // Notification for deleting MDS
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Medication schedule for ${selectedEvent.patientName} has been deleted.`,
            user: user._id, // Assuming user._id is available
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
        setDeleteConfirmModalShow(false); // Close the confirmation modal
        setEventClickShowModal(false); // Close the details modal
        getEvents(); // Refresh the events list
      })
      .catch((error) => {
        ErrorNotification(error);
      });
  };
  const handleDeleteEventByRepeatCycle = () => {
    if (!selectedEvent || !selectedEvent.mdsRepeatCycle) {
      ErrorNotification("No repeat cycle found for this event.");
      return;
    }
    dispatch(
      deleteMdsByPharmacyAndRepeatCycleAction(
        pharmacyId,
        selectedEvent.mdsRepeatCycle
      )
    )
      .then(() => {
        dispatch(
          createNotificationByPharmacyAction(pharmacyId, {
            content: `Entire Medication Cycle for ${selectedEvent.patient.firstName} ${selectedEvent.patient.lastName} and medication ${selectedEvent.medicationDetails[0].name} and more have been deleted.`,
            user: user._id, // Assuming user._id is available
            forRole: ["admin", "manager"],
            priority: "high",
          })
        );
        setDeleteConfirmModalShow(false); // Close the confirmation modal
        setEventClickShowModal(false); // Close the details modal
        getEvents(); // Refresh the events list
        getMdsRepeatCycles();
      })
      .catch((error) => {
        ErrorNotification(error);
      });
  };

  const handleEventDrop = (event) => {
    // Update the event's start and end times
    const eventId = event.event._id;
    const updatedStart = new Date(event.start);
    const updatedEnd = new Date(event.end);

    // Create the updated appointment data
    const mdsData = {
      ...event,
      start: updatedStart,
      end: updatedEnd,
      isDraggable: true,
    };

    // Dispatch an action to update the appointment in Redux
    dispatch(updateMdsByPharmacyAction(pharmacyId, eventId, mdsData))
      .then(() => {
        // Update the events in the calendar (if needed)
        getEvents();
      })
      .catch((error) => {
        ErrorNotification(error);
      });
  };

  const handleEventResize = (props) => {
    // You can dispatch an action to update the event's start and end times in Redux here
    // The 'resizeType' parameter can help you determine if it's a start or end resize
    console.log(props, "resized data");
    const eventId = props.event._id;
    const updatedStart = new Date(props.start);
    const updatedEnd = new Date(props.end);

    // Create the updated appointment data
    const mdsData = {
      start: updatedStart,
      end: updatedEnd,
    };

    // Dispatch an action to update the appointment in Redux
    dispatch(updateMdsByPharmacyAction(pharmacyId, eventId, mdsData))
      .then(() => {
        // Update the events in the calendar (if needed)
        getEvents();
      })
      .catch((error) => {
        ErrorNotification(error);
      });
  };

  // Helpers
  // Time fix
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatDate = (dateString) => {
    return new Intl.DateTimeFormat("en-GB", {
      // Or any other locale you prefer
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: timeZone, // Use the detected or user-specified time zone
    }).format(new Date(dateString));
  };
  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    const timeZoneOffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTime = new Date(date - timeZoneOffset)
      .toISOString()
      .slice(0, 16);
    return localISOTime;
  };

  // Fetchers

  const getEvents = () => {
    dispatch(fetchMdsByPharmacyAction(pharmacyId)).catch((error) => {
      ErrorNotification(error);
    });
  };
  const getMdsRepeatCycles = () => {
    dispatch(fetchMdsRepeatCyclesByPharmacyAction(pharmacyId)).catch(
      (error) => {
        ErrorNotification(error);
      }
    );
  };
  useEffect(() => {
    getEvents();
    getMdsRepeatCycles();
  }, []);

  // New useEffect hook to detect changes in selectedPatient and update the modal form
  useEffect(() => {
    if (selectedPatient) {
      setNewEvent({
        ...newEvent,
        patientName: `${selectedPatient.title} ${selectedPatient.firstName} ${selectedPatient.lastName}`,
        nhsNumber: selectedPatient.nhsNumber,
        patientId: selectedPatient._id,
      });
      handleShowModal(); // Automatically show the modal when a patient is selected
    }
  }, [selectedPatient]);

  return (
    <div>
      <div className="calendar-booking-container"></div>

      <DraggableCalendar
        localizer={localizer}
        events={events}
        startAccessor={(event) => {
          return new Date(event.start);
        }}
        endAccessor={(event) => {
          return new Date(event.end);
        }}
        style={{ height: 500 }}
        onEventDrop={handleEventDrop} // Handle event drop
        draggableAccessor={"isDraggable"}
        resizable={true}
        onEventResize={handleEventResize}
        onSelectEvent={handleEventShowModal}
      />

      {/* Book/Edit Medication Dispensing Record Modal */}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{editedEvent ? "Edit" : "Set"} Repeat Cycle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {/* Pre-fill Patient Name */}
            <Form.Group controlId="formPatientName">
              <Form.Label>Patient Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter patient name"
                value={newEvent.patientName || ""}
                readOnly // Add if you want to make this field read-only
              />
            </Form.Group>

            {/* Pre-fill and disable NHS Number */}
            <Form.Group controlId="formNHSNumber">
              <Form.Label>NHS Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="NHS Number"
                value={newEvent.nhsNumber || ""}
                readOnly // Make this field read-only
              />
            </Form.Group>

            {newEvent.medicationDetails.map((med, index) => (
              <div key={index} className="row align-items-end">
                <div className="col-md-3">
                  <Form.Group controlId={`medicationName-${index}`}>
                    <Form.Label>Medication Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Medication"
                      value={med.name}
                      onChange={(e) =>
                        handleMedicationChange(index, "name", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Group controlId={`medicationDosage-${index}`}>
                    <Form.Label>Dosage</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Dosage"
                      value={med.dosage}
                      onChange={(e) =>
                        handleMedicationChange(index, "dosage", e.target.value)
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-md-3">
                  <Form.Group controlId={`medicationQuantity-${index}`}>
                    <Form.Label>Quantity</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Quantity"
                      value={med.quantity}
                      onChange={(e) =>
                        handleMedicationChange(
                          index,
                          "quantity",
                          e.target.value
                        )
                      }
                    />
                  </Form.Group>
                </div>
                <div className="col-md-3 d-flex justify-content-between">
                  {/* Trash Icon for removing medication */}
                  {index > 0 && (
                    <Button
                      variant="danger"
                      onClick={() => handleRemoveMedication(index)}
                    >
                      <CiTrash />
                    </Button>
                  )}
                  {index === newEvent.medicationDetails.length - 1 && (
                    <Button onClick={handleAddMedication}>
                      <MdAddchart />
                    </Button>
                  )}
                </div>
              </div>
            ))}

            <div className="row">
              {/* Start Date and Time */}
              <div className="col-md-6">
                <Form.Group controlId="formStartDate">
                  <Form.Label>Start Date and Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={formatDateForInput(newEvent.start)}
                    onChange={(e) => handleStartDateChange(e.target.value)}
                  />
                </Form.Group>
              </div>

              {/* End Date and Time */}
              <div className="col-md-6">
                <Form.Group controlId="formEndDate">
                  <Form.Label>End Date and Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={formatDateForInput(newEvent.end)}
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, end: e.target.value })
                    }
                  />
                </Form.Group>
              </div>
            </div>

            <Form.Group controlId="formNotes">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter any notes"
                value={newEvent.notes || ""}
                onChange={(e) =>
                  setNewEvent({ ...newEvent, notes: e.target.value })
                }
              />
            </Form.Group>

            {/* Repeat Cycle Configuration */}
            <Form.Group controlId="formRepeatEnabled">
              <Form.Check
                type="switch"
                label="Enable Repeat Cycle"
                checked={repeatEnabled}
                onChange={(e) => setRepeatEnabled(e.target.checked)}
              />
            </Form.Group>

            {repeatEnabled && (
              <>
                <Form.Group controlId="formRepeatFrequency">
                  <Form.Label>Repeat Frequency</Form.Label>
                  <Form.Control
                    as="select"
                    value={newEvent.repeatFrequency || "None"}
                    onChange={(e) =>
                      setNewEvent({
                        ...newEvent,
                        repeatFrequency: e.target.value,
                      })
                    }
                  >
                    <option value="None">None</option>
                    <option value="Daily">Daily</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Custom">Custom</option>
                  </Form.Control>
                </Form.Group>

                {newEvent.repeatFrequency === "Custom" && (
                  <Form.Group controlId="formCustomRepeatPattern">
                    <Form.Label>Custom Repeat Pattern</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Describe custom pattern, e.g., Every 3 days"
                      value={newEvent.customPattern || ""}
                      onChange={(e) =>
                        setNewEvent({
                          ...newEvent,
                          customPattern: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                )}

                <Form.Group controlId="formRepeatUntil">
                  <Form.Label>Repeat Until (optional)</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={
                      newEvent.repeatUntil
                        ? formatDateForInput(newEvent.repeatUntil)
                        : ""
                    }
                    onChange={(e) =>
                      setNewEvent({ ...newEvent, repeatUntil: e.target.value })
                    }
                  />
                </Form.Group>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveEvent}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
      {/* View Event Details Modal */}
      <Modal show={eventClickShowModal} onHide={handleEventCloseModal}>
        <Modal.Header closeButton>
          {selectedEvent && (
            <Modal.Title>
              {selectedEvent.patient.firstName +
                " " +
                selectedEvent.patient.lastName +
                " Medication"}
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <div>
              <h4>Medication Details</h4>
              {selectedEvent.medicationDetails.map((med, index) => (
                <div key={index} className="row mb-3">
                  <div className="col-md-4">
                    <p>
                      <strong>Name:</strong> {med.name}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <strong>Dosage:</strong> {med.dosage}
                    </p>
                  </div>
                  <div className="col-md-4">
                    <p>
                      <strong>Quantity:</strong> {med.quantity}
                    </p>
                  </div>
                </div>
              ))}

              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Start Date:</strong>{" "}
                    {formatDate(selectedEvent.start)}
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    <strong>End Date:</strong> {formatDate(selectedEvent.end)}
                  </p>
                </div>
              </div>

              <p>
                <strong>Notes:</strong>{" "}
                {selectedEvent.notes || "No notes available"}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleEventCloseModal}>
            Close
          </Button>
          {selectedEvent && selectedEvent.mdsRepeatCycle && (
            <Button variant="info" onClick={() => handleEditAllOccurrences()}>
              Edit All Occurrences
            </Button>
          )}
          <Button
            variant="danger"
            onClick={() => setDeleteConfirmModalShow(true)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Delete Confirmation Modal */}
      <Modal
        show={deleteConfirmModalShow}
        onHide={() => setDeleteConfirmModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this medication dispensing record?
          </p>
          {selectedEvent && selectedEvent.repeatCycle && (
            <p>
              This event is part of a repeat cycle. Do you want to delete this
              single event or all events in the series?
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setDeleteConfirmModalShow(false)}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => handleDeleteEvent(selectedEvent)}
          >
            Delete This Event
          </Button>
          {selectedEvent && selectedEvent.mdsRepeatCycle && (
            <Button
              variant="warning"
              onClick={() => handleDeleteEventByRepeatCycle(selectedEvent)}
            >
              Delete All in Series
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MdsCalendar;
