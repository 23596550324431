import React, { useEffect, useState } from "react";
import { fetchPlansAction } from "../../../redux/actions/planActions";
import "./plan.css";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import { FaCheckCircle } from "react-icons/fa";
import {
  createSubscription,
  manageSubscription,
  cancelSubscription,
} from "../../../api/stripeApi";
import { getAdminSubscriptionAction } from "../../../redux/actions/adminActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Utility function to format Unix timestamp into a readable date
const formatUnixDate = (unixTimestamp) => {
  if (!unixTimestamp) return "N/A";
  return new Date(unixTimestamp * 1000).toLocaleDateString();
};
function Plan() {
  const dispatch = useDispatch();
  const [billingCycleToggled, setBillingCycleToggled] = useState("Monthly");
  const [loading, setLoading] = useState(true); // Unified loading state
  const [loadingAction, setLoadingAction] = useState(false); // Loading state for actions
  const plans = useSelector((state) => state.plan.plans);
  const user = useSelector((state) => state.auth.user);
  const admin = useSelector((state) => state.admin);

  const currentSubscription = admin.currentSubscription;

  useEffect(() => {
    dispatch(fetchPlansAction());
    dispatch(getAdminSubscriptionAction());
  }, [dispatch]);

  useEffect(() => {
    if (plans.length > 0 && user && currentSubscription !== undefined) {
      setLoading(false);
    }
  }, [plans, user, currentSubscription]);

  const formatFeatureText = (feature) => {
    const splitIndex = feature.indexOf(":");
    if (splitIndex !== -1) {
      return (
        <span className="plan-text">
          <strong>{feature.substring(0, splitIndex)}</strong>
          {feature.substring(splitIndex)}
        </span>
      );
    }
    return feature;
  };

  const handlePlanClick = async (e, plan) => {
    e.preventDefault();
    const pricingId =
      billingCycleToggled === "Monthly"
        ? plan.stripeMonthlyPriceId
        : plan.stripeAnnualPriceId;

    setLoadingAction(true);
    if (!currentSubscription || currentSubscription.currentPlan === "Free") {
      try {
        const res = await createSubscription({ pricingId });
        window.location.href = res.data.checkoutUrl;
      } catch (err) {
        toast.error("Subscription creation failed.");
        setLoadingAction(false);
      }
    } else {
      try {
        const res = await manageSubscription();
        window.location.href = res.data.portalUrl;
      } catch (err) {
        toast.error("Failed to manage subscription.");
        setLoadingAction(false);
      }
    }
  };

  const handleCancelSubscription = async () => {
    setLoadingAction(true); // Set loading state when cancelling

    try {
      const res = await cancelSubscription();
      toast.success("Subscription cancelled successfully");
      // Optionally, you can refresh the subscription state or redirect the user
      dispatch(getAdminSubscriptionAction()); // Refresh subscription data
    } catch (err) {
      toast.error("Failed to cancel subscription");
    } finally {
      setLoadingAction(false); // Reset loading state
    }
  };

  const isCurrentPlan = (plan) => {
    if (!currentSubscription || !currentSubscription?.currentPlan) {
      return false;
    }
    const currentBillingCycle = currentSubscription?.currentPlan
      .toLowerCase()
      .includes("monthly")
      ? "monthly"
      : "yearly";
    const currentBillingCycleToggled = (
      billingCycleToggled === "Monthly" ? "monthly" : "yearly"
    ).trim();

    return (
      user?.currentPlan &&
      currentBillingCycle === currentBillingCycleToggled &&
      currentSubscription?.subscriptionStatus.toLowerCase() === "active"
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="plan-section">
      <ToastContainer />
      <div className="container-fluid">
        <div className="current-plan-container">
          <div>
            <div className="current-plan-text">
              <strong>Current Plan:</strong>{" "}
              {currentSubscription?.currentPlan || "None"}
            </div>
            <div className="current-plan-text">
              <strong>Current Status:</strong>{" "}
              {currentSubscription?.subscriptionStatus.toLowerCase() ||
                "Inactive"}
            </div>
            <p className="current-date-plan-text">
              <strong>Subscription Dates:</strong>{" "}
            </p>
            <div className="current-plan-dates-container">
              <div className="current-date-text">
                {formatUnixDate(currentSubscription?.subscription?.startPeriod)}
              </div>
              {/* dash */}
              <div className="current-date-text">-</div>
              <div className="current-date-text">
                {formatUnixDate(currentSubscription?.subscription?.endPeriod)}
              </div>
            </div>
          </div>
          <div>
            {currentSubscription?.subscriptionStatus === "ACTIVE" && (
              <Button
                variant="danger"
                className="cancel-subscription-button"
                onClick={handleCancelSubscription}
                disabled={loadingAction} // Disable while processing
              >
                {loadingAction ? "Cancelling..." : "Cancel Subscription"}
              </Button>
            )}
          </div>
        </div>

        <div className="plan-toggle-container">
          <ToggleButtonGroup
            type="radio"
            name="options"
            value={billingCycleToggled}
            onChange={(value) => setBillingCycleToggled(value)}
          >
            <ToggleButton
              id="Monthly"
              value="Monthly"
              variant="outline-primary"
            >
              Monthly
            </ToggleButton>
            <ToggleButton
              id="Yearly"
              value="Yearly"
              variant="outline-secondary"
            >
              Yearly
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className="d-flex flex-wrap justify-content-around plan-container">
          {plans?.map((plan, index) => (
            <Card key={index} className="plan-card">
              <Card.Body>
                <Card.Title className="plan-title">{plan.name}</Card.Title>
                <Card.Text className="plan-description">
                  {plan.metaDescription}
                </Card.Text>
                <Card.Text className="plan-price">
                  Price:{" "}
                  {billingCycleToggled === "Monthly"
                    ? `$${plan.monthlyPrice}`
                    : `$${plan.annualPrice}`}
                </Card.Text>
                <ul className="features-list">
                  {plan.features.map((feature, idx) => (
                    <li key={idx}>
                      <FaCheckCircle className="feature-icon" />
                      {formatFeatureText(feature.feature)}
                    </li>
                  ))}
                </ul>
              </Card.Body>
              {plan.name === "Free" ? (
                <Button disabled>
                  {currentSubscription?.currentPlan === "Free"
                    ? "Active"
                    : "Free Plan"}
                </Button>
              ) : isCurrentPlan(plan) ? (
                <Button disabled>Active</Button>
              ) : (
                <Button
                  onClick={(e) => handlePlanClick(e, plan)}
                  disabled={loadingAction}
                >
                  {loadingAction ? "Processing..." : "Subscribe"}
                </Button>
              )}
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Plan;
